<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <div class="webNew-dispflex">
       
       <div class="backbox" style="margin-left: 3px;">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
         Students View
       </h4>
     </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <!-- <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-user-graduate"></i
                ></span>
                <h1>Manage Students</h1>
              </div>
            
            </div> -->
            <div class="widjetcontent">
              <div class="form-row p-3">
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                    type="text"
                    disabled
                    :value="getClassName($route.params.classId)"
                    name="studentFirstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                  <label for="stdFirstName" 
                    >Class Name <span class="required">*</span></label
                  >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                    type="text"
                    disabled
                    :value="getSectionName($route.params.sectionId)"
                    name="studentFirstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="Section"
                  />
                  <label for="stdFirstName" 
                    >Section <span class="required">*</span></label
                  >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                    type="text"
                    disabled
                    :value="studentList.length"
                    name="studentFirstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                  <label for="stdFirstName" 
                    >No of students <span class="required">*</span></label
                  >
                  </span>
                </div>
              </div>
              <div class="tbllist mt-1">
                <div class="restable">
                  <div class="resrow resheader">
                    <!-- <div class="cell" style="width: 10%">Select</div> -->
                    <div class="cell">Student Name</div>
                    <div class="cell">Date of Joining</div>
                    <div class="cell">Gender</div>
                   
                    <div class="cell">Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(student, index) in studentList"
                    :key="index"
                  >
                    <!-- <div class="cell chkbox" data-title="Select">
                      <label class="custcheckbox">
                        <input type="checkbox" />
                        <span class="checkmarkchk"></span>
                      </label>
                    </div> -->
                    <div class="cell" data-title="Student Name">
                      <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRouteToViewStudent(student._id)">{{ student.firstName + " " + student.lastName }} </a>

                      
                    </div>
                    <div class="cell" data-title="Student Name"> {{ student.doj | dateFormat }} </div>
                      <div class="cell" data-title="Student Name" style="padding-left: 23px;"> {{ student.gender || '-' }}</div>
                   
                    <div class="cell" data-title="Action" >
                       <b-dropdown id="dropdown-1" text="" right  style="margin-right:3px">
                          
                            <b-dropdown-item @click.prevent="showTransferPopUp(student._id)">Transfer Section</b-dropdown-item>
                          
                          </b-dropdown>
                      <button class="btn round orange tooltipt" @click.prevent="redirectRouteToViewStudent(student._id)">
                         <i class="far fa-eye"></i><span class="tooltiptext">View</span>
                      </button>
                        <button class="btn round red tooltipt" @click="showDeleteModal(student._id)"  >
                          <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="widjetfooter text-center dk_iconsml">
              <!-- to="/admission/home" -->
              <!-- <button type="button" class="btn btnsml" @click="$router.go(-1)">
                <i class="fa fa-arrow-left"></i> <span>Back</span>
              </button> -->
              <!-- <button type="button" class="btn btnsml">
                <i class="far fa-clone"></i> <span>Cloned</span>
              </button>
              <button type="button" class="btn btnsml">
                <i class="far fa-trash-alt"></i> <span>Delete</span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="addstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>Add Student</h2>
        <br />
        <div style="height: 350px; overflow-y: auto">
          <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="contactDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="studentLastName"
                    v-model="contactDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                    placeholder="Last Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="stdEmail"
                    v-model="contactDetails.email"
                    class="form-control form-input"
                    id="stdEmail"
                    placeholder="Secondary Email"
                  />
                  <label for="stdEmail"
                    >Secondary Email</label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    name="status"
                    class="form-control form-input"
                    id="enrollmentStatus"
                    v-model="contactDetails.admissionStatus"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in enrollmentStatusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                    <label
                    v-if="errors.first('studentvalidate.status')"
                    class="vi-error"
                    style="top:-1em"
                    >Enrollment status is required</label
                  >
                 
                  <label for="enrollmentStatus" v-else
                    >Enrollment Status <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                   v-validate="'required'"
                    type="date"
                    max="9999-12-31"
                    onkeydown="return false"
                    name="DOB"
                    v-model="contactDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                    placeholder="Date of Birth"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                   v-validate="'required'"
                    type="date"
                    max="9999-12-31"
                    onkeydown="return false"
                    name="DOJ"
                    v-model="contactDetails.doj"
                    class="form-control form-input"
                    id="DOJ"
                    placeholder="Date of Joining"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOJ')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of Joining is required</label
                  >
                 
                 
                  <label for="DOJ" v-else
                    >Date of Joining <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    v-model="contactDetails.bloodGroup"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(blood, index) in bloodGroupList"
                      :key="index"
                      :value="blood"
                    >
                      {{ blood }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('studentvalidate.Blood Group')"
                    class="vi-error"
                    style="top:-1em"
                    >Blood group is required</label
                  >
                  <label for="bloodGroup" v-else
                    >Blood Group <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Siblings?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.siblings"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>

                  <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="contactDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.gender')"
                            class="vi-error"
                            style="top:-1em"
                            >Gender is required</label
                          > 
                          <label for="firstName" v-else
                            >Gender <span class="required">*</span></label
                          >
                        </span>
                      </div>
              <fieldset class="accordion-group-container">
                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    Parent Details
                  </label>
                    <div class="form-row">
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Father'">
                          <input
                            type="text"
                            name="fthrname"
                            v-validate="'required'"
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Mother'">
                          <input
                            type="text"
                            name="Mother"
                            v-validate="'required'"
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="Mother"
                            placeholder="Mother Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Mother')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Guardian'">
                          <input
                            type="text"
                            name="Guardian"
                            v-validate="'required'"
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="Guardian"
                            placeholder="Guardian Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Guardian')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian Name <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <!-- <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="mthrname"
                            v-validate="'required'"
                            v-model="userDetails.lastName"
                            class="form-control form-input"
                            id="mthrnameid"
                            placeholder="Mother Name"
                          />
                           <label
                            v-if="errors.first('studentvalidate.mthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother Name is required</label
                          >
                          <label for="mthrnameid" v-else
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                      </div> -->
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="userDetails.occupation"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Occupation"
                          />
                          <label for="occid"
                            >Occupation </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            v-validate="'required'"
                            type="email"
                            name="ptemail"
                            :disabled="editId"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                          />
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top:-1em"
                            >Primary Email Id is required</label
                          >
                      
                          <label for="ptemailid" v-else
                            >Primary Email <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            maxlength="10"
                            type="number"
                            v-validate="'required|numeric|min:10|max:10'"
                            name="ptph"
                            v-model="userDetails.phoneNo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                          <label
                            v-if="errors.first('studentvalidate.ptph')"
                            class="vi-error"
                            style="top:-1em"
                            >Phone number is required and only 10 digits</label
                          >
                          <label v-else for="ptphid">Phone <span class="required">*</span></label>
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            v-model="userDetails.address"
                            class="form-control form-input"
                            id="stadrid"
                            placeholder="Address Details"
                          />
                          <label for="stadrid"
                            >Address Details</label
                          >
                        </span>
                      </div>
                    </div>
                </div>

                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    Candidate Enrollment Details
                  </label>
                  <div class="form-row">
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          v-validate="'required'"
                          type="text"
                          name="roll No allocated"
                          v-model="contactDetails.rollNoAllocated"
                          class="form-control form-input"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />
                        <label
                    v-if="errors.first('studentvalidate.roll No allocated')"
                    class="vi-error"
                    style="top:-1em"
                    >Roll no allocated is required</label
                  >
                 
                 
                        <label for="rnaltid" v-else
                          >Roll No. allocated
                          <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          v-validate="'required'"
                          name="AcademicYear"
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="contactDetails.academicYear"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in academicYearList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.from }} - {{ item.to }}
                          </option>
                        </select>
                        <label
                    v-if="errors.first('studentvalidate.AcademicYear')"
                    class="vi-error"
                    style="top:-1em"
                    >Academic year is required</label
                  >
                 
                  
                        <label for="acyearid" v-else
                          >Academic Year <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                       
                         <select
                          v-validate="'required'"
                          name="Class"
                          class="form-control form-input"
                          id="deptlistid"
                          @change="getSectionList(contactDetails.class)"
                          v-model="contactDetails.class"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in classList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.className }}  
                          </option>
                        </select>
                        <label
                    v-if="errors.first('studentvalidate.Class')"
                    class="vi-error"
                    style="top:-1em"
                    >Class name is required</label
                  >
                 
                 
                        <label for="clname" v-else
                          >Class Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                       
                         <select
                          v-validate="'required'"
                          name="Section"
                          class="form-control form-input"
                          id="deptlistid"
                          :disabled="!contactDetails.class"
                          v-model="contactDetails.classSection"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in sectionList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.name }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('studentvalidate.Section')"
                            class="vi-error"
                            style="top:-1em"
                            >Section is required</label
                          >
                 
                 
                        <label for="clname" v-else
                          >Section<span class="required">*</span></label
                        >
                      </span>
                    </div>
                    
                  </div>
                </div>

                <div >
                 
                  <label v-if="contactDetails.siblings"
                    style="margin: 0"
                    for="accordiongroup-3"
                    class="blutitle accordion-label"
                  >
                    Siblings Details
                  </label>
                  <div v-if="contactDetails.siblings" class="form-row">
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <select
                          v-validate="'required'"
                          name="sblgname"
                          v-model="contactDetails.siblingName"
                          class="form-control form-input"
                          id="sblgname"
                          @change="setSblingRollNo(contactDetails.siblingName)"
                          placeholder="Student Name"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in studentList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.firstName }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('studentvalidate.sblgname')"
                            class="vi-error"
                            style="top:-1em"
                            >Sibling Name is required</label
                          >
                        <label v-else for="sblgname"
                          >Sibling Name </label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="rollno"
                          disabled
                          v-model="contactDetails.siblingRollNo"
                          class="form-control form-input"
                          id="rollno"
                          placeholder="Roll No."
                        />
                        <label for="rollno"
                          >Roll No</label
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
             
              <div class="text-center dk_iconsml">
                
                <button v-if="editId"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateStudent"
                >
                    <span>Update</span>
                </button>
                 <button v-else
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="createStudent"
                >
                    <span>Save</span>
                </button>
                <button type="button" @click.prevent="closePoup" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="transferSectionPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeTransferPoup">×</button>
      
      <div class="contact-form">
        <h2>Transfer To Other Section</h2>
        <br />
        <div style="height: 150px">
          <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div  class="form-row">
                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <select
                          name="sbsslgname"
                          class="form-control form-input"
                          id="sblgname"
                          v-model="transferSectionId"
                        >
                         <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in cloneSectionList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.name }}  
                          </option>
                        </select>
                       
                        <label for="sblgname"
                          >Transfer Section </label
                        >
                      </span>
                    </div>
                  
            </div>
            <div
              class=" mt-3 pt-3"
            >
             
              <div class="text-center dk_iconsml">
                
                <button 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  :disabled="!transferSectionId"
                  @click.prevent="updateStudentTransfer"
                >
                    <span>Update</span>
                </button>
              
                <button type="button" @click.prevent="closeTransferPoup" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="deleteStudentPopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>
      <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
           <div
                class="pt-3 text-center dk_iconsml"
                style="border-top: 1px solid #e5e5e5"
              >
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="confirmDelete"
                >
                    <span>Confirm</span>
                </button>
                <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
        </div>
      <!-- </div> -->
    </b-modal>
    
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "Class",
  components: {
    ViSpinner,
  },
  data() {
    return {
      contactDetails: {
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        pointOfContact: 'Father',
        academicYear: null,
        gender: "male",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: null,
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        siblingName: null,
        siblingRollNo: "",
      },

      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
      },

      enrollmentStatusList: [
        "Active",
        "Inactive",
        "Submitted", 
        "In Review",
        "Assessment",
        "Approved", 
        "Reject", 
        "Enrolled"
      ],
      pointOfList: [
       'Father',
       'Mother',
       'Guardian'
      ],
      genderList: [
       'male',
       'female'
      ],
      selectClass : null,
      deleteId: null,
      transferSectionId: null,
      editId: null,
      editStudentId: null,
      userId: null,
      classList: [],
      sectionList: [],
      cloneSectionList: [],
      studentList: [],
      academicYearList: [],
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
    };
  },
  created() {
    this.getAcademicYearList();
    this.getClassList();
    this.getStudentList();
    this.getSectionList(this.$route.params.classId)
  },
   computed: {
     styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
     
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
      this.$router.push({ path: data });
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    redirectRouteToViewStudent(id){
      let userData = secureUI.sessionGet("user");

      if(userData.userDetails.profile.name == "School Admin"){
       // /admission/student/view/details/${student._id}`
        this.$router.push(`/admission/student/view/details/${id}`);
      }else{
        this.$router.push(`/teacher/student/view/details/${id}`);

      }
      // class: this.classId,
      //     room: this.classRoom,
    },
    showdrop() {
        var showdp = document.getElementById("showdropmenu");
        if (showdp.style.display === "block") {
          showdp.style.display = "none";
        } else {
          showdp.style.display = "block";
        }
        setTimeout(function () {
              showdp.style.display='none';
          }, 10000);
          return false;
    },
    getClassName(classid){
      const classDetails =  this.classList.find((x) => x._id == classid);
      if(classDetails){
        return classDetails.className
      }else{
        return ''  
      }
      
    },
    getSectionName(sectionId){
      const sectionDetails =  this.sectionList.find((x) => x._id == sectionId);
      if(sectionDetails){
        return sectionDetails.name
      }else{
        return ''  
      }
    },
    setSblingRollNo(id){
     const sblingDetails =  this.studentList.find((x) => x._id == id);
     this.contactDetails.siblingRollNo = sblingDetails.rollNoAllocated
    },
    async getSectionList(classId){
      if(classId){
        this.sectionList = []
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/getSectionList?class=${classId}`,userData.token);
        if (response.isSuccess) {
          this.sectionList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    async createStudent() {
       let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.viLoader = true;

        const response = await ViService.viXPost(
          "/contact/student/schooladmission",
          {
            contactDetails: this.contactDetails,
            userDetails: this.userDetails,
          },
          userData.token
        );
        this.viLoader = null;

        if (response.isSuccess) {
          this.closePoup();
          this.selectClass = null;
          this.getStudentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    async updateStudent() {
       let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.viLoader = true;
        const response = await ViService.viXPut(
          `/contact/student/updateSchooladmission/${this.editId}/${this.userId}`,
          {
            contactDetails: this.contactDetails,
            userDetails: this.userDetails,
          },
          userData.token
        );
       
        this.viLoader = null;

        if (response.isSuccess) {
          this.closePoup();
          this.selectClass = null;
          this.getStudentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },

    editStudentModal(index){
      this.editId = null
      const studentDetails = this.studentList[index];
      const cloneStudentDetails = { ...studentDetails }
      this.contactDetails = cloneStudentDetails

      if(this.contactDetails.dob){
        this.contactDetails.dob = this.contactDetails.dob.substring(0,10);
      }
      if(this.contactDetails.doj){
        this.contactDetails.doj = this.contactDetails.doj.substring(0,10);
      }
      if(this.contactDetails.class){
        this.getSectionList(this.contactDetails.class)
      }

      this.userDetails = cloneStudentDetails.parent
      this.editId = cloneStudentDetails._id
      this.userId = cloneStudentDetails.parent._id


      this.$bvModal.show("addstudpop");
    },
    
    showTransferPopUp(id){

       this.editStudentId = id
       const sectionDetails =  this.sectionList.filter((x) => x._id != this.$route.params.sectionId);
       this.cloneSectionList = sectionDetails
       this.$bvModal.show("transferSectionPopUp");

    },
    closeTransferPoup(){
      this.editStudentId = null
      this.cloneSectionList = []
      this.$bvModal.hide("transferSectionPopUp");
    },
    
    async updateStudentTransfer() {

       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viXPut(
          `/contact/student/transferSection/${this.editStudentId}`,
          {
            section: this.transferSectionId
          },
          userData.token
        );
       
       // this.viLoader = null;

        if (response.isSuccess) {
          this.closeTransferPoup();
         // this.selectClass = null;
          this.getStudentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    showDeleteModal(id){
        this.deleteId = id
        this.$bvModal.show("deleteStudentPopup");
    },
    closeDeletePopUp(){
        this.deleteId = null
        this.$bvModal.hide("deleteStudentPopup");
    },
    clearForm(){

            this.contactDetails.firstName = "";
            this.contactDetails.lastName = "";
            this.contactDetails.admissionType = "online";
            this.contactDetails.dob = null;
            this.contactDetails.academicYear = null;
            this.contactDetails.gender = "male";
            this.contactDetails.parentId = null;
            this.contactDetails.accountId = null;
            this.contactDetails.email = "";
            this.contactDetails.mobileOne = 0;
            this.contactDetails.mobileTwo = 0;
            this.contactDetails.image = "";
            this.contactDetails.addressOne = "";
            this.contactDetails.addressTwo = "";
            this.contactDetails.state = "";
            this.contactDetails.district = "";
            this.contactDetails.postalCode = "";
            this.contactDetails.country = "";
            this.contactDetails.classApplied = "";
            this.contactDetails.bloodGroup = null;
            this.contactDetails.admissionStatus = null;
            this.contactDetails.siblings = false;
            this.contactDetails.rollNoAllocated = "";
            this.contactDetails.class = null;
            this.contactDetails.classSection = "";
            this.contactDetails.siblingName = "";
            this.contactDetails.siblingRollNo = "";
            this.userDetails.firstName = "";
            this.userDetails.lastName = "";
            this.userDetails.fatherName = "";
            this.userDetails.motherName = "";
            this.userDetails.email = "";
            this.userDetails.phoneNo = 0;
            this.userDetails.password = "";
            this.userDetails.role = "";
            this.userDetails.membership = "Free";
            this.userDetails.userId = "";
            this.userDetails.gender = "";
            this.userDetails.occupation = "";
            this.userDetails.religion = "";
            this.userDetails.nationality = "";
            this.userDetails.address = "";
            this.userDetails.registeredAs = "";
            this.userDetails.userDevices = [];
            this.userDetails.profile = null,
            this.userId = null,
            this.editId = null
    },
    closePoup(){
     
     this.$bvModal.hide("addstudpop");
     this.clearForm();
    },
    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/view/getStudentList?classId=${this.$route.params.classId}&sectionId=${this.$route.params.sectionId}`,userData.token);
        if (response.isSuccess) {
          this.studentList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/contact/getClassList",
          userData.token
        );
        if (response.isSuccess) {
          this.classList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getStudentRollNo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/contact/getRollNo",
          userData.token
        );
        if (response.isSuccess) {
          const rollNo = response.data.rollno;
          if(rollNo){
            this.contactDetails.rollNoAllocated = rollNo
          }
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
     async confirmDelete() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        this.viLoader = true;
        const response = await ViService.viDelete(`/contact/student/RemoveClass/admission/${this.deleteId}`,userData.token);

		if (response.isSuccess) {
			this.$bvModal.hide("deleteStudentPopup");
			this.deleteId = null;
      this.getStudentList();
      this.$toasted.success(response.message);
    } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
    }
      }
    },
  },
};
</script>